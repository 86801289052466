<template>
  <v-app class="bg-dark" :class="classes">
    <!-- NAV -->
    <liNav />
    <!-- MAIN -->
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="`${$route.name}${$route.params.identifier}`" />
      </transition>
    </v-main>
    <!-- /.MAIN -->
    <liInfoModal ref="liInfoModal" />
    <!-- QUESTIONS -->
    <v-tooltip top v-if="$route.name != 'Home' && $route.name != 'Projects' && $route.name != 'Project' && $route.name != 'Login' && $route.name != 'Logout' && questionsDialog == false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark color="white" v-bind="attrs" v-on="on" class="btn-questions" style="bottom: 0; right: 0; position: fixed; margin: 25px; z-index: 100" @click.prevent="questionsDialog = true">
          <v-icon dark class="primary--text">
            {{ appConfig.texts.app.questions.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ appConfig.texts.app.questions.tooltip }}</span>
    </v-tooltip>
    <!-- QUESTIONS -->
    <v-dialog v-model="questionsDialog" :width="isMobileApp ? '350' : '500'">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ appConfig.texts.app.questions.title }}
        </v-card-title>
        <v-card-text>
          <p class="mt-3 text-center" v-html="appConfig.texts.app.questions.description" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="">
          <div class="d-block w-100 text-center">
            <v-btn class="mx-sm-2" depressed text color="primary" :block="isMobileApp" @click="questionsDialog = false">
              {{ appConfig.texts.app.questions.btn }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FOOTER -->
    <liFooter />
  </v-app>
</template>
<script>

export default {
  name: "DEHNapp",
  data: () => ({
   
    questionsDialog: false,
  }),
  watch: {
    $route() {
      // console.log(to)
      this.checkRoute();
    },
  },
  mounted() {
    if (this.products == null) {
      this.fetchProducts();
    }
    if (this.info == null) {
      this.fetchInfo();
    }
  },
  methods: {
    checkRoute() {
      const token = localStorage.getItem(this.cookieName);
      if (this.$router.currentRoute.meta.requiresAuth && token) {
        this.$store.dispatch("auth/reconnect").then((response) => {
          if (response != "200") {
            this.$router.push({ name: "Login" });
          }
        });
      } else if (this.$router.currentRoute.meta.requiresAuth && token == null) {
        this.$router.push({ name: "Login" });
      }
    },
    resetProject() {
      this.$store.dispatch("liDehnProject/SET_PROJECT");
      this.$router.push("/");
    },
    viewProject() {
      this.$router.push("/result");
    },
    hasHistory() {
      return window.history.length > 2;
    },
    hasProject() {
      if (this.project != null && this.project.type != undefined && this.project.type != "") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.checkRoute();
  },
  computed: {
    classes(){
      let _class = this.$route.name != null ? this.$route.name.toLowerCase():'';
          _class = `${_class} ${this.installEnv.toLowerCase()}`;
          return _class.trim();
    },
    projectDialog() {
      return this.$store.getters["liDehnProject/getProjectDialog"];
    },
    currentRouteName() {
      return this.$route.name;
    },
    computedProjectType() {
      if (this.project != null && this.project.type != undefined && this.project.type != "") {
        return this.appConfig.types.find((type) => type.identifier === this.project.type).name;
      } else {
        return false;
      }
    },
    computedProjectGrid() {
      if (this.project.type != "00r" && this.project != null && this.project.grid != undefined && this.project.grid != "" && this.project.grid != "-") {
        // return false;
        return this.appConfig.grid.find((grid) => grid.identifier === this.project.grid).name;
      } else if (this.project.type == "00r" && this.project != null && this.project.grid == "-") {
        return this.appConfig.grid.find((grid) => grid.identifier === "TT").name;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>
