<template>
  <v-container fluid class="liProject body-2 pa-0 bg-dark" style="margin-top: 64px; position: relative">
    <template v-if="project != null && items != null">
      <!-- ALERTS -->
      <v-snackbar v-model="snackbar" elevation="20" :timeout="snackbarTimeout" absolute top right :color="snackbarColor">
        {{ snackbarText }}
      </v-snackbar>
      <!-- /. ALERTS -->
      <!-- HEADER -->
      <v-sheet elevation="0" class="pa-0 mb-1">
        <div class="d-flex pt-0 justify-content-between body-3 w-100">
          <v-btn color="white" @click.prevent="$router.push('/projects')" elevation="0" light class="mr-3" style="max-width: 70px; min-width: unset; padding: 0px; width: 130px; min-height: 70px; border-right: 1px solid #e0e0e0 !important">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mr-2 pt-2" style="width: calc(100% - 360px); min-width: 300px">
            <label v-html="appConfig.texts.project.formProject.nameLabel" />
            <v-text-field hide-details dense v-model="project[0].name" required />
          </div>
          <div class="mr-2 pt-2" style="min-width: 200px">
            <label v-html="appConfig.texts.project.formProject.reference_numberLabel" />
            <v-text-field hide-details dense v-model="project[0].reference_number" required />
          </div>
          <div class="mr-2 pt-2" style="min-width: 160px">
            <label v-html="appConfig.texts.project.formProject.external.label" />
            <v-select dense hide-details :items="appConfig.texts.project.formProject.external.options" v-model="project[0].external" @input="checkProjectItems" item-value="identifier" item-text="name" @change="checkAllItemsExternal()" />
          </div>
          <div class="mr-2 pt-2" style="min-width: 100px" v-if="project[0].external != '0'">
            <label v-html="appConfig.texts.project.formProject.lpl.label" />
            <v-select dense hide-details :items="appConfig.texts.project.formProject.lpl.options" v-model="project[0].lpl" @input="checkProjectItems" item-value="identifier" item-text="name" @change="checkAllItemsLpl()" />
          </div>
          <div class="mr-2 pt-2" style="min-width: 100px">
            <label v-html="appConfig.texts.project.formProject.grid.label" />
            <v-select dense hide-details :items="appConfig.grid" v-model="project[0].grid" @input="checkProjectItems" item-value="identifier" item-text="name" @change="checkAllItemsGrid()" />
          </div>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small plain @click.stop="downloadAsExcel()" style="max-width: 70px; min-width: unset; padding: 0px; width: 130px; min-height: 70px; border-left: 1px solid #e0e0e0 !important">
                <v-icon color="green darken-3">mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>{{ appConfig.texts.project.formProject.buttonLabels.saveAsExcel }}</span>
          </v-tooltip>
          <!-- SAVE -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small plain @click.stop="updateProject" style="max-width: 70px; min-width: unset; padding: 0px; width: 130px; min-height: 70px; border-left: 1px solid #e0e0e0 !important">
                <template v-if="autoSaveLoader">
                  <v-progress-circular indeterminate color="dark" class="ma-auto"></v-progress-circular>
                </template>
                <template v-else>
                  <v-icon>mdi-check</v-icon>
                </template>
              </v-btn>
            </template>
            <span>{{ appConfig.texts.project.formProject.buttonLabels.save }}</span>
          </v-tooltip>
          <!-- /. SAVE -->
        </div>
      </v-sheet>
      <!-- /. HEADER -->
      <!-- LOADINGDATA FOR WHEN ADDING MAIN DIVIDER -->
      <div v-if="loadingData" style="min-height: calc(100vh - 197px)" class="d-flex white">
        <v-progress-circular indeterminate color="primary" class="ma-auto"></v-progress-circular>
      </div>
      <!-- ITEMS -->
      <div v-else style="min-height: calc(100vh - 197px)" class="d-flex white">
        <!-- EDIT ITEM -->
        <div class="bg-dark pa-0" style="width: 100%; max-width: 350px">
          <v-sheet v-if="selected != null" elevation="0" class="body-3 mx-1 bg-medium position-relative" color="light">
            <v-form ref="editItem" v-model="valid" lazy-validation>
              <v-sheet class="liEditItem position-relative" :class="{ note: selected.type == 0 }" color="transparent">
                <div class="pa-1">
                  <perfect-scrollbar>
                    <div class="inner pb-15">
                      <!-- √√√ NAME -->
                      <div class="w-100 white pt-3 px-3">
                        <label>{{ appConfig.texts.project.form.name.label }}</label>
                        <v-text-field dense validate-on-blur v-model="selected.name" :placeholder="appConfig.texts.project.form.name.placeholder" />
                      </div>
                      <!-- √√√ MAIN DIVIDER -->
                      <template v-if="selected.type == 9000">
                        <div class="white mt-1">
                          <!-- AMPERE -->
                          <div class="w-100 pt-3 px-3">
                            <div class="d-flex w-100">
                              <label>{{ appConfig.texts.project.form.amp.label }} </label>
                              <liInfoModalBtn infoIdentifier="ampereMD" styleClasses="ml-auto mr-0" />
                            </div>
                            <v-autocomplete dense clearable :placeholder="appConfig.texts.project.form.amp.placeholder" :items="appConfig.texts.project.form.amp.options[`all`]" v-model="selected.ampere" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(selected)" @click:clear="checkMainDivider(selected)" />
                          </div>
                          <!-- KORTSLUITVASHEID -->
                          <div class="white w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                              <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto mr-0" />
                            </div>
                            <v-select dense clearable :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="selected.shortCircuitResistance" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(selected)" @click:clear="checkMainDivider(selected)" />
                          </div>
                          <!-- INTEGREATE FUSE -->
                          <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(selected)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                            <div class="d-flex w-100">
                              <label>{{ appConfig.texts.project.form.preAmp.label }}</label>
                              <liInfoModalBtn infoIdentifier="integratedFuseMD" styleClasses="ml-auto mr-0" />
                            </div>
                            <v-select dense clearable :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="selected.preAmp" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(selected)" @click:clear="checkMainDivider(selected)" />
                          </div>
                        </div>
                      </template>
                      <!-- TYPE -->
                      <template v-else>
                        <div class="w-100 white pt-3 px-3 mt-1">
                          <div class="d-flex w-100">
                            <label v-html="appConfig.texts.project.form.type.label" />
                            <liInfoModalBtn infoIdentifier="sdOrCe" styleClasses="ml-auto" />
                          </div>
                          <v-radio-group dense v-model="selected.type" row required @change="setType(selected)">
                            <template v-for="(option, index) in appConfig.texts.project.form.type.options">
                              <v-radio :key="index" :value="option.value" class="mr-5" :disabled="option.value == 2 && selected._items.length > 0">
                                <template v-slot:label>
                                  <div class="d-flex align-center"><v-img :src="option.icon" width="30px" class="mr-3" :style="option.value == 2 && selected._items.length > 0 ? 'opacity:.5' : ''" /> {{ option.name }}</div>
                                </template>
                              </v-radio>
                            </template>
                          </v-radio-group>
                        </div>
                      </template>

                      <!-- ### √ SUB DIVIDER -->
                      <template v-if="selected.type == 1">
                        <!-- LOCATION -->
                        <template v-if="rule_NeedLocation()">
                          <div class="w-100 white pt-3 px-3 mt-1">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.external.labelSd" />
                              <liInfoModalBtn infoIdentifier="locationSD" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.external" row required @change="checkDivider(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.external.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" @input="checkDivider(selected)"></v-radio>
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- CABLE LENGTH -->
                        <div class="w-100 white pt-3 px-3 mt-1">
                          <div class="d-flex w-100">
                            <label v-if="selectedDepth == 1" v-html="appConfig.texts.project.form.cableLength.labelSdFirst" />
                            <label v-else v-html="appConfig.texts.project.form.cableLength.labelSd" />
                            <liInfoModalBtn infoIdentifier="cableLengthSD" styleClasses="ml-auto" />
                          </div>
                          <v-radio-group dense v-model="selected.cableLength" row required @change="setCableLength(selected)">
                            <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
                              <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                            </template>
                          </v-radio-group>
                        </div>

                        <!-- LPL ZONE -->
                        <template v-if="rule_NeedLpZone(selected)">
                          <div class="w-100 white pt-3 px-3 mt-1">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.lplZone.labelSd" />
                              <liInfoModalBtn infoIdentifier="lpz0ZoneSD" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZone" row required @change="setLpZone(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.lplZone.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- LPL ZONE = B Question on ROOFTOP ? -->
                        <template v-if="rule_NeedLpZone(selected) && rule_NeedLpZoneRooftop(selected)">
                          <div class="w-100 white px-3">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.lplZoneRooftop.labelSd" />
                              <liInfoModalBtn infoIdentifier="roofSD" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZoneRooftop" row required>
                              <template v-for="(option, index) in appConfig.texts.project.form.lplZoneRooftop.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- LPL ZONE = B && on rooftop Question CONNECTED -->
                        <template v-if="rule_NeedLpZone(selected) && rule_NeedLpZoneRooftop(selected) && rule_NeedLpZoneRooftopConnected(selected)">
                          <div class="w-100 white px-3">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.rooftopConnected.labelSd" />
                              <liInfoModalBtn infoIdentifier="roofConnectedSD" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZoneRooftopConnected" row required>
                              <template v-for="(option, index) in appConfig.texts.project.form.rooftopConnected.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- NO PRODUCT REQUIRED CABLE LENGTH < 10 -->
                        <div v-if="rule_ProductNotRequired(selected)" class="mt-3 w-100 d-flex px-3 pt-3 white">
                          <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
                        </div>

                        <!-- PRODUCT -->
                        <template v-if="selected.cableLength != ''">
                          <!-- GRID -->
                          <div class="w-100 white pt-3 px-3 mt-1" v-if="project[0].grid == 'TN-C'">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.grid.label" />
                              <liInfoModalBtn infoIdentifier="grid" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.grid" row :rules="defaultRules" required @change="setGrid(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.grid.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                              </template>
                            </v-radio-group>
                          </div>
                          <div class="white mt-1">
                            <!-- AMPERE -->
                            <div v-if="ampereOptionsSd != null" class="w-100 pt-3 px-3" :class="{ liDisabled: ampereOptionsSd == null }">
                              <div class="d-flex w-100">
                                <label v-html="appConfig.texts.project.form.amp.label" />
                                <liInfoModalBtn infoIdentifier="ampereSD" styleClasses="ml-auto" />
                              </div>
                              <v-autocomplete clearable dense :placeholder="appConfig.texts.project.form.amp.placeholder" :items="ampereOptionsSd" v-model="selected.ampere" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                            <!-- KORTSLUITVASHEID -->
                            <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                              <div class="d-flex w-100">
                                <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                                <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto" />
                              </div>
                              <v-autocomplete clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="selected.shortCircuitResistance" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                            <!-- PHASE -->
                            <template v-if="rule_NeedPhaseSd(selected)">
                              <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                                <div class="d-flex w-100">
                                  <label v-html="appConfig.texts.project.form.phase.label" />
                                  <liInfoModalBtn infoIdentifier="phase" styleClasses="ml-auto" />
                                </div>
                                <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.phase.placeholder" :items="appConfig.texts.project.form.phase.options" v-model="selected.phase" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                              </div>
                            </template>
                            <!-- INTEGREATED FUSE -->
                            <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(selected)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                              <div class="d-flex w-100">
                                <label v-html="appConfig.texts.project.form.preAmp.label" />
                                <liInfoModalBtn infoIdentifier="integratedFuseSD" styleClasses="ml-auto" />
                              </div>
                              <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="selected.preAmp" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                          </div>
                        </template>
                      </template>

                      <!-- ### √ CRITICAL END GROUP -->
                      <template v-if="selected.type == 2">
                        <!-- LOCATION -->
                        <template v-if="rule_NeedLocation()">
                          <div class="w-100 white pt-3 px-3 mt-1">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.external.labelCe" />
                              <liInfoModalBtn infoIdentifier="locationCE" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.external" row required @change="checkDivider(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.external.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- CABLE LENGTH -->
                        <div class="w-100 white pt-3 px-3 mt-1">
                          <div class="d-flex w-100">
                            <!-- <label v-if="selectedDepth == 1" v-html="appConfig.texts.project.form.cableLength.labelSdFirst" />
                            <label v-else-if="selected.type == 1" v-html="appConfig.texts.project.form.cableLength.labelSd" /> -->
                            <!-- <label v-else v-html="appConfig.texts.project.form.cableLength.labelCe" /> -->
                            <label v-html="appConfig.texts.project.form.cableLength.labelCe" />
                            <liInfoModalBtn infoIdentifier="cableLengthCE" styleClasses="ml-auto" />
                          </div>
                          <v-radio-group dense v-model="selected.cableLength" row required @change="setCableLength(selected)">
                            <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
                              <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                            </template>
                          </v-radio-group>
                        </div>

                        <!-- LPL ZONE -->
                        <template v-if="rule_NeedLpZone(selected)">
                          <div class="w-100 white pt-3 px-3 mt-1">
                            <div class="d-flex w-100">
                              <!-- <label v-if="selected.type == 1" v-html="appConfig.texts.project.form.lplZone.labelSd" /> -->
                              <!-- <label v-else v-html="appConfig.texts.project.form.lplZone.labelCe" /> -->
                              <label v-html="appConfig.texts.project.form.lplZone.labelCe" />
                              <liInfoModalBtn infoIdentifier="lpz0ZoneCE" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZone" row required @change="setLpZone(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.lplZone.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- LPL ZONE = B Question on ROOFTOP ? -->
                        <template v-if="rule_NeedLpZone(selected) && rule_NeedLpZoneRooftop(selected)">
                          <div class="w-100 white px-3">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.lplZoneRooftop.labelCe" />
                              <liInfoModalBtn infoIdentifier="roofCE" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZoneRooftop" row required @change="checkDivider(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.lplZoneRooftop.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- LPL ZONE = B && on rooftop Question CONNECTED -->
                        <template v-if="rule_NeedLpZone(selected) && rule_NeedLpZoneRooftop(selected) && rule_NeedLpZoneRooftopConnected(selected)">
                          <div class="w-100 white px-3">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.rooftopConnected.labelCe" />
                              <liInfoModalBtn infoIdentifier="roofConnectedCE" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.lplZoneRooftopConnected" row required @change="checkDivider(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.rooftopConnected.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10" />
                              </template>
                            </v-radio-group>
                          </div>
                        </template>

                        <!-- NO PRODUCT REQUIRED CABLE LENGTH < 10 -->
                        <div v-if="rule_ProductNotRequired(selected)" class="mt-3 w-100 d-flex px-3 pt-3 white">
                          <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
                        </div>

                        <!-- PRODUCT -->
                        <template v-if="selected.cableLength != ''">
                          <!-- GRID -->
                          <div class="w-100 white pt-3 px-3 mt-1" v-if="project[0].grid == 'TN-C'">
                            <div class="d-flex w-100">
                              <label v-html="appConfig.texts.project.form.grid.label" />
                              <liInfoModalBtn infoIdentifier="grid" styleClasses="ml-auto" />
                            </div>
                            <v-radio-group dense v-model="selected.grid" row :rules="defaultRules" required @change="setGrid(selected)">
                              <template v-for="(option, index) in appConfig.texts.project.form.grid.options">
                                <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                              </template>
                            </v-radio-group>
                          </div>
                          <div class="white mt-1">
                            <!-- AMPERE -->
                            <div v-if="ampereOptionsCe != null" class="w-100 pt-3 px-3" :class="{ liDisabled: ampereOptionsCe == null }">
                              <div class="d-flex w-100">
                                <label>{{ appConfig.texts.project.form.amp.label }} </label>
                                <liInfoModalBtn infoIdentifier="ampereSD" styleClasses="ml-auto" />
                              </div>
                              <v-autocomplete clearable dense :placeholder="appConfig.texts.project.form.amp.placeholder" :items="ampereOptionsCe" v-model="selected.ampere" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                            <!-- KORTSLUITVASHEID -->
                            <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                              <div class="d-flex w-100">
                                <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                                <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto" />
                              </div>
                              <v-autocomplete clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="selected.shortCircuitResistance" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                            <!-- PHASE -->
                            <template v-if="rule_NeedPhaseSd(selected)">
                              <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                                <div class="d-flex w-100">
                                  <label v-html="appConfig.texts.project.form.phase.label" />
                                  <liInfoModalBtn infoIdentifier="phase" styleClasses="ml-auto" />
                                </div>
                                <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.phase.placeholder" :items="appConfig.texts.project.form.phase.options" v-model="selected.phase" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                              </div>
                            </template>
                            <!-- INTEGREATE FUSE -->
                            <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(selected)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                              <div class="d-flex w-100">
                                <label v-html="appConfig.texts.project.form.preAmp.label" />
                                <liInfoModalBtn infoIdentifier="integratedFuseSD" styleClasses="ml-auto" />
                              </div>
                              <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :required="!selected.preAmp" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="selected.preAmp" item-value="value" item-text="name" @input="checkDivider(selected)" @click:clear="checkDivider(selected)" />
                            </div>
                          </div>
                        </template>
                      </template>

                      <!-- √√√ SELECTED PRODUCT -->
                      <div class="w-100 px-3 py-3 white" v-if="selected.type == 9000 || (!rule_ProductNotRequired(selected) && selected.type != '' && selected.cableLength != '')">
                        <template v-if="!loadingItem && selected.product != '' && product(selected.product) != undefined">
                          <span class="font-weight-bold w-100 border-bototm"><label v-html="appConfig.texts.project.form.selectProduct.label" /></span>
                          <div class="d-flex w-100 mt-1">
                            <v-img :src="`${apiPublicUrl}${product(selected.product).thumbnailpath}`" class="mx-auto pa-1" width="100%" max-width="100px" max-height="100px" aspect-ratio="1" />
                            <div class="w-100 ml-3">
                              <v-chip v-if="project[0].external == 1 && (selected.external == 1 || selected.external == '1')" color="blue" style="height: 19px" class="px-2 mr-1 body-4 white--text text-center"><b> 2x </b></v-chip>
                              <b> {{ product(selected.product).name }} </b><br />
                              {{ product(selected.product).article_number }}<br />
                              <!-- PRODUCT LINK -->
                              <template v-if="product(selected.product).multiple_url != '' && Array.isArray(JSON.parse(product(selected.product).multiple_url))">
                                <a v-for="(_item, index) in JSON.parse(product(selected.product).multiple_url)" :key="index" :href="_item.url" target="_blank" class="text-none d-block"> {{ _item.label }} {{ appConfig.texts.project.form.productAdvice.selecproductLinkButtonLabel }} </a>
                              </template>
                              <a v-else-if="product(selected.product).url != ''" :href="product(selected.product).url" target="_blank" class="text-none"> {{ appConfig.texts.project.form.productAdvice.selecproductLinkButtonLabel }} </a>
                              <!-- PRODUCT OUTSIDE LPZ0 - EXTRA PRODUCT NEEDED-->
                              <div v-if="project[0].external == 1 && (selected.external == 1 || selected.external == '1')" class="w-100 d-flex white mt-1">
                                <v-alert dense type="info" class="rounded-md w-100">
                                  <div v-if="selected.type == 1" class="w-100" v-html="appConfig.texts.project.form.productAdvice.outsideDubbleProductSD" />
                                  <div v-if="selected.type == 2" class="w-100" v-html="appConfig.texts.project.form.productAdvice.outsideDubbleProductCE" />
                                </v-alert>
                              </div>
                              <!-- PRODUCT NOTE -->
                              <div v-if="'productNote' in selected && selected.productNote != null && selected.productNote != ''" class="w-100 d-flex white mt-1">
                                <v-alert dense type="warning" icon="mdi-alert" class="rounded-md w-100"><div class="w-100" v-html="selected.productNote" /></v-alert>
                              </div>
                            </div>
                          </div>
                        </template>

                        <div class="w-100 pa-5 d-flex" v-if="loadingItem">
                          <v-progress-circular indeterminate color="red" class="mt-5 mx-auto" />
                        </div>

                        <!-- NO PRODUCT FOUND -->
                        <div v-if="'noProductReason' in selected && selected.noProductReason != ''" class="w-100 d-flex white">
                          <v-alert dense text type="error" icon="mdi-alert" class="rounded-md w-100"><span v-html="selected.noProductReason" /></v-alert>
                        </div>
                      </div>

                      <!-- √√√ NOTES -->
                      <div class="w-100 white pt-3 px-3 mt-1">
                        <div class="d-flex w-100">
                          <label>{{ appConfig.texts.project.form.description.label }}</label>
                          <liInfoModalBtn infoIdentifier="note" styleClasses="ml-auto" />
                        </div>
                        <v-textarea dense validate-on-blur v-model="selected.description" rows="2" :placeholder="appConfig.texts.project.form.description.placeholder"></v-textarea>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </v-sheet>
            </v-form>
          </v-sheet>
          <!-- LOADING ITEM -->
          <div class="w-100 pa-5 d-flex" v-if="loadingSelected">
            <v-progress-circular indeterminate color="white" class="mt-5 mx-auto" />
          </div>
        </div>
        <!-- /. EDIT ITEM -->
        <!-- LIST ITEMS -->
        <div class="pa-0" style="width: calc(100% - 350px)">
          <!-- TABS MAIN DIVIDERS -->
          <v-sheet class="dark bg-dark">
            <v-tabs class="bg-dark liMdTabGroup" v-model="tabMainDividers" dark grow background-color="transparent">
              <v-tab v-for="(md, index) in items" :key="index" class="liMdTabItem cursor-pointer">
                <div class="inner w-100 d-flex" :class="{ active: selected != null && selected.identifier == md.identifier }" @click.stop="selectMainDivider(md, index)">
                  <!-- PRODUCT -->
                  <div v-if="product(md.product) != undefined" class="text-dark cursor-pointer d-flex align-center pr-1 py-0 pl-2 w-100">
                    <div class="liDescription" style="width: calc(30% - 25px)">
                      <div class="liTypeIcon my-auto">
                        <v-img :src="appConfig.texts.project.types[0].icon" width="30px" />
                      </div>
                      <!-- NAME -->
                      <span class="text-s my-auto" v-text="md.name" />
                    </div>
                    <div style="width: 75px" />
                    <div style="width: calc(40% - 25px)">
                      <div class="liProductChip">
                        <v-img :src="`${apiPublicUrl}${product(md.product).thumbnailpath}`" width="30" height="30" max-width="30" max-height="30" class="mr-2" />
                        <div class="d-flex flex-column">
                          <div style="font-size: 11px; margin: 2px 10px 0px 0px">
                            {{ product(md.product).name }} <b class="ml-2 mr-2">{{ product(md.product).article_number }}</b>
                          </div>
                          <!-- PRODUCT TECHNICAL -->
                          <div class="technical text-dark d-flex align-center" style="margin: 2px 0 0; font-size: 11px">
                            <v-tooltip top v-if="md.preAmp == 'ja'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon x-small v-bind="attrs" v-on="on" style="font-size: 11px; margin-right: 3px"> mdi-fuse </v-icon>
                              </template>
                              <span style="font-size: 11px">{{ appConfig.texts.project.form.preAmp.label }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="mr-1"> {{ md.ampere }}<span class="grey--text text-xs">A</span> </span>
                              </template>
                              <span style="font-size: 11px" v-html="appConfig.texts.project.form.amp.label" />
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="mx-1"> {{ md.shortCircuitResistance }}<span style="font-size: 11px" class="grey--text text-xs">kA</span> </span>
                              </template>
                              <span style="font-size: 11px" v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="width: calc(30% - 25px); margin-right: 30px; height: 100%">
                      <!-- PRODUCT NOTE  -->
                      <div class="liProductNote" v-if="'productNote' in md && md.productNote != ''">
                        <span class="note" v-html="md.productNote" />
                      </div>
                    </div>
                  </div>
                  <!-- NO PRODUCT -->
                  <v-chip v-else dark class="body-4 ma-3 rounded-circle liChipIcon" outlined color="warning">
                    <v-icon color="warning">mdi-alert</v-icon>
                  </v-chip>
                </div>
              </v-tab>
            </v-tabs>
          </v-sheet>
          <!-- /. TABS MAIN DIVIDERS -->

          <!-- ITEMS FOR THIS MAIN DIVIDER -->
          <div class="w-100 px-0 pa-0 liSubItemsList">
            <v-tabs-items v-model="tabMainDividers" style="background-color: transparent">
              <v-tab-item v-for="(md, index) in items" :key="index">
                <!-- NO ITEMS -- ADD NEW -->
                <template v-if="md._items != null && md._items.length == 0">
                  <!-- START  -- / ADD ITEMS -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small class="bg-light addItem" style="margin-left: 10px" v-bind="attrs" v-on="on" @click.stop="addToItemDirect(items[tabMainDividers])">
                        <v-icon class="text-dark" small> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span class="body-3">{{ appConfig.texts.project.tableAdd }}</span>
                  </v-tooltip>
                  <!-- END  -- / ADD ITEMS -->
                </template>
                <!--/. NO ITEMS -- ADD NEW -->
                <!--DRAGGABLE TREE ITEMS -->
                <perfect-scrollbar>
                  <nestedDraggable v-model="md._items" @selectedItem="setSelected" :selected="selected" @addToItem="addToItemDirect" @removed="removed" :depth="0" :parent="items[tabMainDividers]" />
                  <template v-if="md._items.length > 0">
                    <!-- START  -- / ADD ITEMS -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small class="bg-light addItem" style="margin-left: 10px" v-bind="attrs" v-on="on" @click.stop="addToItemDirect(items[tabMainDividers])">
                          <v-icon class="text-dark" small> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span class="body-3">{{ appConfig.texts.project.tableAdd }}</span>
                    </v-tooltip>
                    <!-- END  -- / ADD ITEMS -->
                  </template>
                </perfect-scrollbar>
                <!--/. DRAGGABLE TREE ITEMS -->
              </v-tab-item>
            </v-tabs-items>
          </div>

          <!-- /. ITEMS FOR THIS MAIN DIVIDER -->
        </div>
      </div>
      <!-- /. ITEMS -->
    </template>
    <v-row v-else style="min-height: calc(100vh - 60px)">
      <v-progress-circular indeterminate color="primary" class="ma-auto"></v-progress-circular>
    </v-row>
    <liConfirmGrid ref="liConfirmGrid" />
    <liConfirmExternal ref="liConfirmExternal" />
  </v-container>
</template>
<script>
import _ from "lodash";
import * as XLSX from "xlsx";
import nestedDraggable from "../components/draggableLists/nestedDraggable.vue";
export const API_URL = process.env.VUE_APP_FILE_URL;
export default {
  name: "Project",
  title: "Project",
  components: {
    nestedDraggable,
  },
  data() {
    return {
      delay: 2000,
      lastExecution: 0,
      autoSaveLoader: false,
      loadingData: false,
      addChildrenSheet: false,
      addItems: {
        subdividers: 0,
        itemsArray: [],
        critendgroups: 0,
        grid: "",
      },
      addChildItems: {
        subdividers: 0,
        itemsArray: [],
        critendgroups: 0,
        grid: "",
      },
      tabMainDividers: 0,
      // tab: 0,
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 3000,
      snackbarColor: "success",
      // filteredProducts: null,
      advicedProducts: null,
      apiPublicUrl: API_URL,
      valid: true,
      // dragging: false,
      selected: null,
      selectedDepth: null,
      selectedParent: null,
      selectedToAddTo: null,
      loadingSelected: false,
      defaultRules: [(v) => !!v || this.appConfig.texts.project.form.required],
      newItem: {
        external: "",
        cableLength: "",
        identifier: "",
        name: "",
        description: "",
        type: 1,
        product: null,
        color: "",
        ampere: "",
        preAmp: "",
        phase: "",
        grid: "",
        noProductReason: "",
        productNote: "",
        productOptions: [],
        lplZone: "",
        lplZoneRooftop: null,
        lplZoneRooftopConnected: null,
        _items: [],
      },
      ampereOptions: null,
      ampereOptionsSd: null,
      ampereOptionsCe: null,
      shortCircuitResistanceOptions: null,
      items: null,
      addItemsValid: true,
      loadingItem: false,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.identifier;
    },
    projects() {
      return this.$store.getters["liDehnProject/getProjects"];
    },
    project() {
      return this.$store.getters["liDehnProject/getCollection"](`project_${this.projectId}`);
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
    mdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_md`);
    },
    sdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_sd`);
    },
    ceMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_ce`);
    },
  },
  mounted() {
    if (this.projects === null) {
      this.error = true;
    } else {
      if (this.user != null) {
        this.getProject();
        this.setupTitle();
      }
    }
    this.getMatrices();
  },
  watch: {
    user(val) {
      if (val && this.projects == null) {
        this.getProjects();
        this.getProject();
      }
    },
    tabMainDividers(val) {
      if (val) {
        this.resetSelected();
      }
    },
    items(val) {
      if (val != null) {
        if (typeof val === "string") {
          this.items = JSON.parse(val);
        }
        if (typeof val === "object" && val.length == 1 && val[0].product == "") {
          this.setSelected(val[0], null, 0);
        } else if (typeof val === "object" && val.length == 1 && val[0]._items != null && val[0]._items.length == 0) {
          // console.log("no items");
        }
      }
    },
    project(val) {
      if (val) {
        this.items = JSON.parse(val[0].project_items);
        this.setSelected(this.items[0], null, 0);
        this.addItems.grid = val[0].grid;
        this.setupTitle();
        this.checkProjectItems();
      }
    },
    products(val) {
      if (val) {
        this.filteredProducts = _.cloneDeep(this.products);
      }
    },
  },
  methods: {
    downloadAsExcel() {
      const today = new Date();
      const date = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
      const time = `${today.getHours()}:${today.getMinutes()}`;
      const dateTime = `${date}_${time}`;
      const workBook = XLSX.utils.book_new();
      const workBookDataProject = (XLSX.WorkSheet = XLSX.utils.json_to_sheet([]));
      const projectHeading = [["Naam", "Externe bliksembeveiliging", "LPL klassse", "Netstelsel"]];
      XLSX.utils.sheet_add_aoa(workBookDataProject, projectHeading);
      const _projectData = [
        {
          name: this.project[0].name,
          external: this.project[0].external == 0 ? "Nee" : this.project[0].external == 2 ? "Niet bekend" : "Ja",
          lpl: this.project[0].lpl == 0 ? "Niet bekend" : this.project[0].lpl == 1 ? "I" : this.project[0].lpl == 2 ? "II" : this.project[0].lpl == 3 ? "III" : "IV",
          grid: this.project[0].grid,
        },
      ];
      XLSX.utils.sheet_add_json(workBookDataProject, _projectData, { origin: "A2", skipHeader: true });
      XLSX.utils.book_append_sheet(workBook, workBookDataProject, "Project");
      const _copy = this.items != null ? _.cloneDeep(this.items) : [];
      // let  multipleProductsTempArray = null
      _copy.forEach((md) => {
        let _cleanData = [];
        let _mdSave = {};
        _mdSave["type"] = "Hoofdverdeler";
        _mdSave["naam"] = md.name;
        if (!_.isEmpty(md.product) && this.product(md.product).multiple_url != "" && Array.isArray(JSON.parse(this.product(md.product).multiple_url))) {
          // Has multiple products
          _mdSave["productName"] = !_.isEmpty(md.product) ? this.product(md.product).name : "";
          _mdSave["productNo"] = !_.isEmpty(md.product) ? this.product(md.product).article_number : "";
          _mdSave["amount"] = "";
        } else {
          _mdSave["productName"] = !_.isEmpty(md.product) ? this.product(md.product).name : "";
          _mdSave["productNo"] = !_.isEmpty(md.product) ? this.product(md.product).article_number : "";
          _mdSave["amount"] = !_.isEmpty(md.product) ? "1" : "";
        }
        _mdSave["productNote"] = md.productNote;
        _mdSave["external"] = "";
        _mdSave["lpz"] = "";
        _mdSave["ampere"] = md.ampere;
        _mdSave["shortCircuitResistance"] = md.shortCircuitResistance;
        _mdSave["phase"] = md.phase;
        _mdSave["preAmp"] = md.preAmp;
        _mdSave["note"] = md.description;

        _cleanData.push(_mdSave);
        let subDividerReq = (_dividers) => {
          _dividers.forEach((_subDivider) => {
            let _saveItem = {};
            if (_subDivider.type == 1) {
              _saveItem["type"] = "Onderverdeler";
              _saveItem["naam"] = _subDivider.name;
              if (!_.isEmpty(_subDivider.product) && this.product(_subDivider.product).multiple_url != "" && Array.isArray(JSON.parse(this.product(_subDivider.product).multiple_url))) {
                // Has multiple products
                // multipleProductsTempArray = JSON.parse(this.product(_subDivider.product).multiple_url);
                // console.log(multipleProductsTempArray)
                // console.log(multipleProductsTempArray[0]);
                // console.log(this.productByArticleNo(multipleProductsTempArray[0].articleNo));
                // _saveItem["productName"] = !_.isEmpty(multipleProductsTempArray[0]) ? this.product(multipleProductsTempArray[0]).name : "";
                // _saveItem["productNo"] = !_.isEmpty(multipleProductsTempArray[0]) ? this.product(multipleProductsTempArray[0]).article_number : "";
                _saveItem["productName"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).name : "";
                _saveItem["productNo"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).article_number : "";
                _saveItem["amount"] = "";
              } else {
                _saveItem["productName"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).name : "";
                _saveItem["productNo"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).article_number : "";
                _saveItem["amount"] = !_.isEmpty(_subDivider.product) ? "1" : "";
              }
              _saveItem["productNote"] = !_.isEmpty(_subDivider.productNote) ? _subDivider.productNote : "";
              _saveItem["external"] = _subDivider.external == 0 ? "Binnen" : "Buiten";
              _saveItem["lpz"] = _subDivider.external == 1 ? (_subDivider.lplZone == "a" ? "lpz0a" : _subDivider.lplZone == "b" ? "lpz0b" : "lpznb") : "";
              _saveItem["ampere"] = _subDivider.ampere;
              _saveItem["shortCircuitResistance"] = _subDivider.shortCircuitResistance;
              _saveItem["phase"] = _subDivider.phase;
              _saveItem["preAmp"] = _subDivider.preAmp;
              _saveItem["note"] = _subDivider.description;
              _cleanData.push(_saveItem);

              if (_subDivider.external == 1) {
                let _copySaveItem = _.cloneDeep(_saveItem);

                _saveItem["naam"] = `A - ${_saveItem["naam"]}`;
                _saveItem["productNote"] = !_.isEmpty(_saveItem["productNote"]) ? `${_saveItem["productNote"]} ${this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD)}` : this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD);

                _copySaveItem["naam"] = `B - ${_copySaveItem["naam"]}`;
                _copySaveItem["productNote"] = !_.isEmpty(_copySaveItem["productNote"]) ? `${_copySaveItem["productNote"]} ${this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD)}` : this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD);

                _cleanData.push(_copySaveItem);
              }
            }
            if (_subDivider.type == 2) {
              _saveItem["type"] = "Kritische eindgroep";
              _saveItem["naam"] = _subDivider.name;
              if (!_.isEmpty(_subDivider.product) && this.product(_subDivider.product).multiple_url != "" && Array.isArray(JSON.parse(this.product(_subDivider.product).multiple_url))) {
                // Has multiple products
                // multipleProductsTempArray = JSON.parse(this.product(_subDivider.product).multiple_url);
                // console.log(multipleProductsTempArray)
                // console.log(multipleProductsTempArray[0]);
                // console.log(this.productByArticleNo(multipleProductsTempArray[0].articleNo));
                // _saveItem["productName"] = !_.isEmpty(multipleProductsTempArray[0]) ? this.product(multipleProductsTempArray[0]).name : "";
                // _saveItem["productNo"] = !_.isEmpty(multipleProductsTempArray[0]) ? this.product(multipleProductsTempArray[0]).article_number : "";
                _saveItem["productName"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).name : "";
                _saveItem["productNo"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).article_number : "";
                _saveItem["amount"] = "";
              } else {
                _saveItem["productName"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).name : "";
                _saveItem["productNo"] = !_.isEmpty(_subDivider.product) ? this.product(_subDivider.product).article_number : "";
                _saveItem["amount"] = !_.isEmpty(_subDivider.product) ? "1" : "";
              }
              _saveItem["productNote"] = !_.isEmpty(_subDivider.productNote) ? _subDivider.productNote : "";
              _saveItem["external"] = _subDivider.external == 0 ? "Binnen" : "Buiten";
              _saveItem["lpz"] = _subDivider.external == 1 ? (_subDivider.lplZone == "a" ? "lpz0a" : _subDivider.lplZone == "b" ? "lpz0b" : "lpznb") : "";
              _saveItem["ampere"] = _subDivider.ampere;
              _saveItem["shortCircuitResistance"] = _subDivider.shortCircuitResistance;
              _saveItem["phase"] = _subDivider.phase;
              _saveItem["preAmp"] = _subDivider.preAmp;
              _saveItem["note"] = _subDivider.description;
              _cleanData.push(_saveItem);
              if (_subDivider.external == 1) {
                let _copySaveItem = _.cloneDeep(_saveItem);

                _saveItem["naam"] = `A - ${_saveItem["naam"]}`;
                _saveItem["productNote"] = !_.isEmpty(_saveItem["productNote"]) ? `${_saveItem["productNote"]} ${this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD)}` : this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD);

                _copySaveItem["naam"] = `B - ${_copySaveItem["naam"]}`;
                _copySaveItem["productNote"] = !_.isEmpty(_copySaveItem["productNote"]) ? `${_copySaveItem["productNote"]} ${this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD)}` : this.stripTags(this.appConfig.texts.project.form.productAdvice.outsideDubbleProductSD);

                _cleanData.push(_copySaveItem);
              }
            }

            if (_subDivider._items.length > 0) {
              subDividerReq(_subDivider._items);
            }
          });
        };
        if (md._items.length > 0) {
          subDividerReq(md._items);
        }
        const Heading = [["Type", "Naam", "Product", "Product nr.", "Aantal", "Opmerking", "Locatie", "Zone", "Aansluitwaarde", "Kortsluitvastheid", "Fase", "Geïntegreerde voorzekering", "Notitie"]];
        const workBookData = (XLSX.WorkSheet = XLSX.utils.json_to_sheet([]));
        XLSX.utils.sheet_add_aoa(workBookData, Heading);
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(workBookData, _cleanData, { origin: "A2", skipHeader: true });
        // const workBookData = XLSX.utils.json_to_sheet(_cleanData);
        XLSX.utils.book_append_sheet(workBook, workBookData, md.name);
      });

      XLSX.writeFile(workBook, `DEHN_${this.project[0].name}_${dateTime}.xlsx`);
    },
    // COMPONENT FUNCTIONS
    setupTitle() {
      if (this.project != null) {
        this.$title = `Project: ${this.project[0].name}`;
        this.addChildItems.grid = this.project[0].grid;
      }
    },
    // EDIT ITEM FUNCTIONS
    setupDividerAmpereOptions() {
      let index = this.appConfig.texts.project.form.amp.options[`all`].findIndex((option) => option.value == this.selectedParent.ampere);
      if (index == 0) {
        index = 1;
      } else if (index == 1) {
        index = 2;
      } else {
        index = index - 1;
      }
      let ampOptions = this.appConfig.texts.project.form.amp.options[`all`].slice(0, index);
      this.ampereOptions = ampOptions;
    },
    setupCeAmpereOptions() {
      let index = this.appConfig.texts.project.form.amp.options[`ceSpecific`].findIndex((option) => option.value == this.selectedParent.ampere);
      if (index == 0) {
        index = 1;
      } else if (index == 1) {
        index = 2;
      } else {
        index = index - 1;
      }
      let ampOptions = this.appConfig.texts.project.form.amp.options[`ceSpecific`].slice(0, index);
      this.ampereOptionsCe = ampOptions;
    },
    setupSdAmpereOptions() {
      let index = this.appConfig.texts.project.form.amp.options[`sdSpecific`].findIndex((option) => option.value == this.selectedParent.ampere);
      if (index == 0) {
        index = 1;
      } else if (index == 1) {
        index = 2;
      } else {
        index = index - 1;
      }
      let ampOptions = this.appConfig.texts.project.form.amp.options[`sdSpecific`].slice(0, index);
      this.ampereOptionsSd = ampOptions;
    },
    setSelected(item, parent, depth) {
      this.resetSelected();
      this.loadingSelected = true;
      setTimeout(() => {
        this.loadingSelected = false;
        this.selected = item;
        this.selectedDepth = depth;
        this.selectedParent = parent;
        if (this.selectedParent != null) {
          this.setupDividerAmpereOptions();
          this.setupSdAmpereOptions();
          this.setupCeAmpereOptions();
        }
        if ((item.type == 1 && item.ampere != "") || (item.type == 2 && item.ampere != "")) this.checkDivider(item);
        if (item.type == 9000 && item.ampere != "") this.checkMainDivider(item);
      }, 200);
    },
    resetSelected() {
      this.selected = null;
      this.selectedDepth = null;
      this.selectedParent = null;
      this.ampereOptions = null;
      this.ampereOptionsCe = null;
    },
    setType(selectedItem) {
      this.setupDividerAmpereOptions();
      this.setupCeAmpereOptions();
      this.setupSdAmpereOptions();
      this.$set(selectedItem, "product", null);
      this.$set(selectedItem, "prodnoProductReasonuct", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "productOptions", []);
      if (selectedItem.ampere == 24) {
        this.$set(selectedItem, "ampere", "");
      }
      this.checkDivider(selectedItem);
    },
    setGrid(selectedItem) {
      this.clearProduct(selectedItem);
      this.checkDivider(selectedItem);
    },
    setLpZone(selectedItem) {
      this.clearProduct(selectedItem);
      this.checkDivider(selectedItem);
    },
    setCableLength(selectedItem) {
      // console.log(this.selectedParent.ampere);
      if (selectedItem.cableLength == "<10") {
        this.clearProduct(selectedItem);
        // this.$set(selectedItem, "ampere", this.selectedParent.ampere);
        // this.$set(selectedItem, "phase", "");
        // this.$set(selectedItem, "noProductReason", "");
        // this.$set(selectedItem, "product", "");
        // this.$set(selectedItem, "productNote", "");
        // this.$set(selectedItem, "lplZone", "");
        // this.$set(selectedItem, "shortCircuitResistance", this.selectedParent.shortCircuitResistance);
        // this.$set(selectedItem, "grid", this.selectedParent.grid);
        this.checkDivider(selectedItem);
      } else {
        this.checkDivider(selectedItem);
      }
    },

    // PRODUCT FUNCTIONS
    filteredProducts(grid) {
      return _.cloneDeep(this.products.filter((item) => item.grid == grid));
    },
    clearProduct(selectedItem) {
      this.$set(selectedItem, "product", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "productOptions", []);
      if ("productOptions" in selectedItem && selectedItem.productOptions != "") {
        this.$set(selectedItem, "productOptions", "");
      }
    },
    searchProduct(item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.article_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },

    // LIST FUNCTIONS
    addToItem(item) {
      this.setSelectedToAddTo(item);
      this.addChildrenSheet = true;
      this.addChildItems.grid = item.grid;
      let newChildItem = _.cloneDeep(this.newItem);
      newChildItem.identifier = this.generateUUID();
      newChildItem.type = 1;
      newChildItem.grid = this.addChildItems.grid;
      this.addChildItems.itemsArray.push(newChildItem);
    },
    addToItemDirect(item) {
      let newChildItem = _.cloneDeep(this.newItem);
      newChildItem.identifier = this.generateUUID();
      newChildItem.type = "";
      newChildItem.grid = item.grid;
      // this.addChildItems.itemsArray.forEach((child) => {
      item._items = [...item._items, newChildItem];
      // });
    },
    setSelectedToAddTo(item) {
      this.selectedToAddTo = null;
      setTimeout(() => {
        this.selectedToAddTo = item;
      }, 500);
    },
    removed(item) {
      if (this.selected != null && item.identifier == this.selected.identifier) {
        this.resetSelected();
      }
    },

    // ADD ITEM VIA SHEET FUNCTIONS
    closeChildrenSheet() {
      this.addChildItems = {
        subdividers: 0,
        critendgroups: 0,
        itemsArray: [],
        grid: "",
      };
      this.addChildrenSheet = false;
      this.resetSelected();
    },
    addChildItem() {
      let newChildItem = _.cloneDeep(this.newItem);
      newChildItem.identifier = this.generateUUID();
      newChildItem.type = 1;
      newChildItem.grid = this.addChildItems.grid;
      this.addChildItems.itemsArray.push(newChildItem);
    },
    removeChildItem(index) {
      this.addChildItems.itemsArray.splice(index, 1);
    },
    submitChildItems() {
      this.$refs.addItemsForm.validate();
      if (this.addItemsValid) {
        this.addChildItems.itemsArray.forEach((item) => {
          this.selectedToAddTo._items = [...this.selectedToAddTo._items, item];
        });
        this.addChildItems.subdividers = 0;
        this.addChildItems.critendgroups = 0;
        this.addChildItems.grid = this.project[0].grid;
        this.addChildItems.itemsArray = [];
        this.addChildrenSheet = false;
      }
    },

    // MAIN DIVIDER FUNCTIONS
    addMainDivider() {
      this.loadingData = true;
      let newMd = _.cloneDeep(this.newItem);
      newMd.identifier = this.generateUUID();
      newMd.type = 9000;
      newMd.grid = this.items[0].grid;
      newMd.name = this.appConfig.texts.projects.newProject.dividerName;
      // newMd.ampere = this.items[0].ampere;
      // newMd.preAmp = this.items[0].preAmp;
      // newMd.product = this.items[0].product;

      this.items = [...this.items, newMd];
      setTimeout(() => {
        this.tabMainDividers = this.items.length - 1;
        this.autoUpdateProject();
      }, 200);
      setTimeout(() => {
        this.loadingData = false;
      }, 600);
    },
    removeMainDivider(index) {
      if (this.items.length >= 2) {
        if (this.tabMainDividers == index) {
          this.tabMainDividers = index == 0 ? this.tabMainDividers + 1 : this.tabMainDividers - 1;
        }
        this.items.splice(index, 1);
      }
    },
    selectMainDivider(selectedItem, tabIndex) {
      this.tabMainDividers = tabIndex;
      this.setSelected(selectedItem, null, 0);
    },

    // PROJECT FUNCTIONS
    getProject() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].projects,
          where: `status [=>] 1 |  account [=>] ${this.user.account} | identifier [=>] ${this.projectId}`,
          orderBy: "sequence ASC",
          select: "*",
        },
        collectionName: `project_${this.projectId}`,
      });
    },
    updateProject() {
      let projectToSave = _.cloneDeep(this.project);
      projectToSave[0].project_items = JSON.stringify(_.cloneDeep(this.items));
      this.items = null;
      this.$store
        .dispatch("liDehnProject/UPDATE_COLLECTION_ITEM", {
          object: this.entities[this.installEnv].projects,
          item: projectToSave[0],
          user: `${this.user.first_name} ${this.user.last_name}`,
        })
        .then(() => {
          this.snackbarText = this.appConfig.texts.project.saveText;
          this.snackbar = true;
          this.getProject();
          this.getProjects();
          setTimeout(() => {
            this.snackbarText = "";
          }, this.snackbarTimeout);
        });
    },
    autoUpdateProject() {
      if (this.lastExecution + this.delay < Date.now()) {
        this.autoSaveLoader = true;
        let projectToSave = _.cloneDeep(this.project);
        projectToSave[0].project_items = JSON.stringify(_.cloneDeep(this.items));
        this.$store
          .dispatch("liDehnProject/UPDATE_COLLECTION_ITEM", {
            object: this.entities[this.installEnv].projects,
            item: projectToSave[0],
            user: `${this.user.first_name} ${this.user.last_name}`,
          })
          .then(() => {
            setTimeout(() => {
              this.autoSaveLoader = false;
              this.lastExecution = Date.now();
            }, 500);
          });
      }
    },
    getProjects() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].projects,
          where: `status [=>] 1 |  account [=>] ${this.user.account}`,
          orderBy: "sequence ASC",
          select: "*",
        },
        collectionName: `projects`,
      });
    },
    getMatrices() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].matrix_md,
          orderBy: "id ASC",
          where: "status [=>] 1",
          select: "id, identifier, name, opmerkingen, colour, ex-ja, ex-nee, ex-nb, lpl-I, lpl-II, lpl-III, lpl-IV, lpl-nb, aw-25, aw-32, aw-40, aw-63, aw-80, aw-100, aw-125, aw-160, aw-250, aw-315, aw-400, aw-500, aw-630, aw-800, aw-1000, aw-1250, aw-1600, aw-2000, aw-2500, aw-3200, aw-4000, aw-6300, kv-25, kv-50, kv-100, kv-nb, if-ja, if-nee, TN-C, TN-S, TT",
        },
        collectionName: `matrix_md`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].matrix_sd,
          orderBy: "id ASC",
          where: "status [=>] 1",
          select: "id, identifier, name, opmerkingen, colour, status, ex-ja, ex-nee, ex-nb, lpl-I, lpl-II, lpl-III, lpl-IV, lpl-nb, aw-25, aw-32, aw-40, aw-63, aw-80, aw-100, aw-125, aw-160, aw-250, aw-315, aw-400, aw-500, aw-630, aw-800, aw-1000, aw-1250, aw-1600, aw-2000, aw-2500, aw-3200, aw-4000, kv-25, kv-50, kv-100, kv-nb, 1FN, 3FN, buiten, binnen, lpz0a, lpz0b, lpznb, op-dak, if-ja, if-nee, TN-C, TN-S, TT",
        },
        collectionName: `matrix_sd`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: this.entities[this.installEnv].matrix_ce,
          orderBy: "id ASC",
          where: "status [=>] 1",
          select: "id, identifier, name, opmerkingen, colour, status, ex-ja, ex-nee, ex-nb, lpl-I, lpl-II, lpl-III, lpl-IV, lpl-nb, aw-25, aw-32, aw-40, aw-63, aw-80, aw-100, aw-125, aw-160, aw-250, aw-315, aw-400, aw-500, aw-630, aw-800, aw-1000, aw-1250, aw-1600, aw-2000, aw-2500, aw-3200, aw-4000, kv-6, kv-25, kv-50, kv-100, kv-nb, 1FN, 3FN, buiten, binnen, lpz0a, lpz0b, lpznb, op-dak, if-ja, if-nee, TN-C, TN-S, TT",
        },
        collectionName: `matrix_ce`,
      });
    },
    checkProjectItems() {
      // console.log("checkProjectItems");
      this.items.forEach((md) => {
        let subDividerCheck = (_dividers) => {
          _dividers.forEach((_subDivider) => {
            if (_subDivider.type == 1) {
              this.checkDivider(_subDivider);
            }
            if (_subDivider.type == 2) {
              this.checkDivider(_subDivider);
            }

            if (_subDivider._items.length > 0) {
              subDividerCheck(_subDivider._items);
            }
          });
        };
        this.checkMainDivider(md);
        if (md._items.length > 0) {
          subDividerCheck(md._items);
        }
      });
    },

    // RULES
    rule_NeedIntegratedFuse(selectedItem) {
      if (selectedItem.cableLength == "<10") {
        selectedItem.needsPreAmp = "nee";
        return false;
      } else {
        if (Number(this.project[0].external) == 1) {
          // CHECK MET EXTERNE BLIKSEMAFLEIDING
          if (Number(selectedItem.type) == 1 || Number(selectedItem.type) == 2) {
            // ONDERVERDELER EN KRITISCHE EINDGROEP REGEL Geïntegreerde voorzekering
            if (Number(selectedItem.external) == 0) {
              // ONDERVERDELER EN KRITISCHE EINDGROEP (externe bliksem beveiliging locatie binnen) REGEL = AMPERE groter of gelijk aan 16A én kleiner of gelijk aan 1000A én kortsluitvastheid kleiner of gelijk aan 50kArms toon de vraag geintergreerde voorzekering
              if (Number(selectedItem.ampere) <= 125 && (Number(selectedItem.shortCircuitResistance) <= 50 || String(selectedItem.shortCircuitResistance) == "nb")) {
                // console.log("test");
                selectedItem.needsPreAmp = "nee";
                return false;
              } else if (Number(selectedItem.ampere) > 125 && Number(selectedItem.ampere) <= 250 && (Number(selectedItem.shortCircuitResistance) > 50 || String(selectedItem.shortCircuitResistance) == "nb")) {
                selectedItem.needsPreAmp = "nee";
                return false;
              } else {
                selectedItem.needsPreAmp = "ja";
                return true;
              }
            } else {
              // BUITEN
              if (selectedItem.lplZone == "a") {
                if ((Number(this.project[0].lpl) == 0 || Number(this.project[0].lpl) == 1 || Number(this.project[0].lpl) == 2) && Number(selectedItem.ampere) <= 250) {
                  // LPL I & II
                  // AMPERE kleiner dan 250A toon de vraag geintergreerde voorzekering niet
                  selectedItem.needsPreAmp = "nee";
                  return false;
                } else if ((Number(this.project[0].lpl) == 3 || Number(this.project[0].lpl) == 4) && Number(selectedItem.ampere) <= 160 && Number(selectedItem.shortCircuitResistance) <= 25) {
                  // LPL III & IV
                  // AMPERE kleiner dan 160A én kortsluitvastheid kleiner of gelijk aan 25kArms toon de vraag geintergreerde voorzekering niet
                  selectedItem.needsPreAmp = "nee";
                  return false;
                } else if ((Number(this.project[0].lpl) == 3 || Number(this.project[0].lpl) == 4) && Number(selectedItem.ampere) <= 250 && Number(selectedItem.shortCircuitResistance) > 25) {
                  // LPL III & IV
                  // AMPERE kleiner dan 250A én kortsluitvastheid groter dan 25kArms toon de vraag geintergreerde voorzekering niet
                  selectedItem.needsPreAmp = "nee";
                  return false;
                } else {
                  selectedItem.needsPreAmp = "ja";
                  return true;
                }
              } else if (selectedItem.lplZone == "b") {
                if (Number(selectedItem.ampere) <= 125 && Number(selectedItem.shortCircuitResistance) <= 50) {
                  // AMPERE kleiner of gelijk aan 125A én kortsluitvastheid kleiner of gelijk aan 50kArms toon de vraag geintergreerde voorzekering niet
                  selectedItem.needsPreAmp = "nee";
                  return false;
                  // } else if (Number(selectedItem.ampere) > 125 && Number(selectedItem.ampere) <= 250 && (Number(selectedItem.shortCircuitResistance) >= 50 || String(selectedItem.shortCircuitResistance) == 'nb')) {
                  //   // AMPERE kleiner of gelijk aan 125A én kortsluitvastheid kleiner of gelijk aan 50kArms toon de vraag geintergreerde voorzekering niet
                  //   selectedItem.needsPreAmp = "nee";
                  //   return false;
                } else {
                  selectedItem.needsPreAmp = "ja";
                  return true;
                }
              }
            }
          } else {
            // HOOFDVERDELER REGEL Geïntegreerde voorzekering
            if (Number(this.project[0].lpl) == 0 || Number(this.project[0].lpl) == 1 || Number(this.project[0].lpl) == 2) {
              if (Number(selectedItem.ampere) <= 250) {
                selectedItem.needsPreAmp = "nee";
                return false;
              } else {
                selectedItem.needsPreAmp = "ja";
                return true;
              }
            } else if (Number(this.project[0].lpl) == 3 || Number(this.project[0].lpl) == 4) {
              if (Number(selectedItem.ampere) <= 160 && Number(selectedItem.shortCircuitResistance) <= 25) {
                selectedItem.needsPreAmp = "nee";
                return false;
              } else if (Number(selectedItem.ampere) <= 250 && Number(selectedItem.shortCircuitResistance) > 25) {
                selectedItem.needsPreAmp = "nee";
                return false;
              } else {
                selectedItem.needsPreAmp = "ja";
                return true;
              }
            }
          }
        } else {
          // CHECK ZONDER EXTERNE BLIKSEMAFLEIDING
          if (Number(selectedItem.type) == 1 || Number(selectedItem.type) == 2) {
            // ONDERVERDELER EN KRITISCHE EINDGROEP REGEL Geïntegreerde voorzekering
            if (Number(selectedItem.ampere) <= 125 && Number(selectedItem.shortCircuitResistance) <= 50) {
              selectedItem.needsPreAmp = "nee";
              return false;
              // }
              // else  if (Number(selectedItem.ampere) > 125 && Number(selectedItem.ampere) <= 250 &&  (Number(selectedItem.shortCircuitResistance) <= 50 || String(selectedItem.shortCircuitResistance) == 'nb')) {
              //   selectedItem.needsPreAmp = "nee";
              //   return false;
            } else {
              selectedItem.needsPreAmp = "ja";
              return true;
            }
          } else {
            // HOOFDVERDELER REGEL Geïntegreerde voorzekering
            if (Number(selectedItem.ampere) <= 160 && Number(selectedItem.shortCircuitResistance) <= 25) {
              selectedItem.needsPreAmp = "nee";
              return false;
              // } else if (Number(selectedItem.ampere) >= 160 && Number(selectedItem.ampere) <= 250 && (Number(selectedItem.shortCircuitResistance) > 50 || String(selectedItem.shortCircuitResistance) == 'nb')) {
              //   selectedItem.needsPreAmp = "nee";
              //   return false;
            } else {
              selectedItem.needsPreAmp = "ja";
              return true;
            }
          }
        }
      }
    },
    rule_NeedLpZone(selectedItem) {
      return this.project[0].external == 1 && selectedItem.external == 1 && selectedItem.cableLength == ">10" ? true : false;
    },
    rule_NeedLpZoneRooftop(selectedItem) {
      return selectedItem.lplZone == "b" ? true : false;
    },
    rule_NeedLpZoneRooftopConnected(selectedItem) {
      return Number(selectedItem.lplZoneRooftop) == 1 ? true : false;
    },
    rule_IsItemOnRooftopConnected(selectedItem) {
      return selectedItem.lplZoneRooftop != undefined && selectedItem.lplZoneRooftopConnected != undefined && this.rule_NeedLpZoneRooftop(selectedItem) && this.rule_NeedLpZoneRooftopConnected(selectedItem) && Number(selectedItem.lplZoneRooftopConnected == 1) ? true : false;
    },
    rule_NeedLocation() {
      return this.project[0].external == 1 ? true : false;
    },
    rule_ProductNotRequired(selectedItem) {
      return selectedItem.cableLength == "<10" ? true : false;
    },
    rule_NeedPhaseSd(selectedItem) {
      return Number(selectedItem.ampere) <= 63 || Number(selectedItem.ampere) == "" ? true : false;
    },
    rule_SetPhase(selectedItem) {
      return Number(selectedItem.ampere) > 40 ? true : false;
    },

    // CHECK FUNCTIONS
    checkItemSubDividerOutsideWithExternal(selectedItem, item, options) {
      return !_.isEmpty(item[selectedItem.grid]) && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item[options.ex] == 1 && item[options._in] == 1 && item[options.lpl] == 1 && item[options.lpz] == 1 ? true : false;
    },
    checkItemSubDividerOutsideWithExternalOnRoof(selectedItem, item, options) {
      return !_.isEmpty(item[selectedItem.grid]) && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item[options.ex] == 1 && item[options._in] == 1 && item[options.lpl] == 1 && item[options.lpz] == 1 && item["op-dak"] == 1 ? true : false;
    },
    checkItemSubDividerInsideWithExternal(selectedItem, item, options) {
      return !_.isEmpty(item[selectedItem.grid]) && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item[options.ex] == 1 && item[options._in] == 1 && item[options.lpl] == 1 ? true : false;
    },
    checkAllItemsLpl() {
      this.items.forEach((md) => {
        let subDividerCheck = (_dividers) => {
          _dividers.forEach((_subDivider) => {
            if (_subDivider.type == 1) {
              this.checkDivider(_subDivider);
            }
            if (_subDivider.type == 2) {
              this.checkDivider(_subDivider);
            }

            if (_subDivider._items.length > 0) {
              subDividerCheck(_subDivider._items);
            }
          });
        };
        this.checkMainDivider(md);
        if (md._items.length > 0) {
          subDividerCheck(md._items);
        }
      });
    },
    checkAllItemsExternal() {
      this.items.forEach((md) => {
        let subDividerCheck = (_dividers) => {
          _dividers.forEach((_subDivider) => {
            if (_subDivider.type == 1) {
              this.checkDivider(_subDivider);
            }
            if (_subDivider.type == 2) {
              this.checkDivider(_subDivider);
            }
            if (_subDivider._items.length > 0) {
              subDividerCheck(_subDivider._items);
            }
          });
        };
        if (this.project[0].external == 1) {
          this.$refs.liConfirmExternal.open().then((result) => {
            this.project[0].lpl = result;
            this.checkMainDivider(md);
            if (md._items.length > 0) {
              subDividerCheck(md._items);
            }
          });
        } else {
          this.checkMainDivider(md);
          if (md._items.length > 0) {
            subDividerCheck(md._items);
          }
        }
      });
    },
    checkAllItemsGrid() {
      this.items.forEach((md) => {
        let subDividerCheck = (_dividers, _grid) => {
          _dividers.forEach((_subDivider) => {
            _subDivider.grid = _grid;

            if (_subDivider.type == 1) {
              this.checkDivider(_subDivider);
            }
            if (_subDivider.type == 2) {
              this.checkDivider(_subDivider);
            }

            if (_subDivider._items.length > 0) {
              subDividerCheck(_subDivider._items, _subDivider.grid);
            }
          });
        };
        // CHANGE GRID IF NEEDED
        md.grid = md.grid != this.project[0].grid ? this.project[0].grid : md.grid;

        // IF GRID IS TN-C SHOW MODAL
        if (md.grid == "TN-C") {
          this.$refs.liConfirmGrid.open().then((result) => {
            this.checkMainDivider(md);
            if (md._items.length > 0) {
              subDividerCheck(md._items, result);
            }
          });
        } else {
          this.checkMainDivider(md);

          if (md._items.length > 0) {
            subDividerCheck(md._items, md.grid);
          }
        }
      });
    },
    checkMainDivider(selectedItem) {
      setTimeout(() => {
        // console.log("checkMainDivider", selectedItem.needsPreAmp, `if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`);
        this.$set(selectedItem, "productOptions", []);
        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", "");
        this.$set(selectedItem, "noProductReason", "");

        const ex = this.project[0].external == 0 ? "ex-nee" : this.project[0].external == 2 ? "ex-nb" : "ex-ja";
        const lpl = this.project[0].lpl == 0 ? "lpl-nb" : this.project[0].lpl == 1 ? "lpl-I" : this.project[0].lpl == 2 ? "lpl-II" : this.project[0].lpl == 3 ? "lpl-III" : "lpl-IV";
        // console.log(selectedItem.needsPreAmp);
        //* SHORT CIRCUIT RESISTANCE OPTIONS *\\
        let shortCircuitResistanceOptions = [];
        if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
          // console.log('Need to remove all below 33');
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value > 6 && option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance >= 100) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        }
        shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
        this.shortCircuitResistanceOptions = shortCircuitResistanceOptions;

        //* PRODUCT *\\ && !_.isEmpty(selectedItem.preAmp)
        if (!_.isEmpty(selectedItem.ampere) && !_.isEmpty(selectedItem.shortCircuitResistance)) {
          let products = this.mdMatrix.filter((item) => {
            if (this.project[0].external == 0 || this.project[0].external == 2) {
              if (!_.isEmpty(item[selectedItem.grid]) && item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`]) {
                return item;
              }
            } else {
              if (!_.isEmpty(item[selectedItem.grid]) && item[ex] == 1 && item[lpl] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`]) {
                return item;
              }
            }
          });

          if (products != undefined && products.length > 0) {
            if ((selectedItem.needsPreAmp == "ja" && !_.isEmpty(selectedItem.preAmp)) || selectedItem.needsPreAmp == "nee") {
              // console.log(products[0]);
              if (products[0] != undefined && products[0][selectedItem.grid] != null) {
                this.$set(selectedItem, "product", products[0][selectedItem.grid]);
                this.$set(selectedItem, "productNote", products[0].opmerkingen);
                this.$set(selectedItem, "noProductReason", "");
                if (products.length > 1) {
                  let productIdentifiers = [];
                  products.forEach((product) => {
                    productIdentifiers.push(product[selectedItem.grid]);
                  });
                  this.$set(selectedItem, "productOptions", productIdentifiers);
                }
              } else {
                this.$set(selectedItem, "productOptions", []);
                this.$set(selectedItem, "product", "");
                this.$set(selectedItem, "productNote", "");
                if (!_.isEmpty(selectedItem.preAmp)) {
                  this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
                }
              }
            } else {
              this.$set(selectedItem, "productOptions", []);
              this.$set(selectedItem, "product", "");
              this.$set(selectedItem, "productNote", "");
              if (!_.isEmpty(selectedItem.preAmp)) {
                this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
              }
            }
          } else {
            this.$set(selectedItem, "productOptions", []);
            this.$set(selectedItem, "product", "");
            this.$set(selectedItem, "productNote", "");
            if (!_.isEmpty(selectedItem.preAmp)) {
              this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
            }
          }
        }
        this.autoUpdateProject();
      }, 60);
    },
    checkDivider(selectedItem) {
      this.loadingItem = true;
      setTimeout(() => {
        this.loadingItem = false;
        const ex = this.project[0].external == 0 ? "ex-nee" : this.project[0].external == 2 ? "ex-nb" : "ex-ja";
        const _in = selectedItem.external == 0 ? "binnen" : "buiten";
        const lpl = this.project[0].lpl == 0 ? "lpl-nb" : this.project[0].lpl == 1 ? "lpl-I" : this.project[0].lpl == 2 ? "lpl-II" : this.project[0].lpl == 3 ? "lpl-III" : "lpl-IV";
        const lpz = selectedItem.lplZone == "a" ? "lpz0a" : selectedItem.lplZone == "b" ? "lpz0b" : "lpznb";

        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", "");
        this.$set(selectedItem, "noProductReason", "");
        this.$set(selectedItem, "productOptions", []);

        let shortCircuitResistanceOptions = [];
        if (selectedItem.type == 1) {
          //* SHORT CIRCUIT RESISTANCE RULES SUB DIVIDER *\\
          if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
            this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
              if (option.value > 6 && option.value < 100) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
              this.$set(selectedItem, "shortCircuitResistance", "");
            }
          } else if (selectedItem.ampere >= 800) {
            this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
              if (Number(option.value) > 6) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance == 6) {
              this.$set(selectedItem, "shortCircuitResistance", "");
            }
          } else if (selectedItem.ampere <= 32) {
            this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
              if (Number(option.value) < 100) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance >= 100) {
              selectedItem.shortCircuitResistance = "";
            }
          }
          this.shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
        } else {
          //* SHORT CIRCUIT RESISTANCE RULES CRITICAL END GROUP *\\
          if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
            this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
              if (Number(option.value) > 6 && Number(option.value) < 100) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
              this.$set(selectedItem, "shortCircuitResistance", "");
            }
          } else if (selectedItem.ampere >= 800) {
            this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
              if (Number(option.value) > 6) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance == 6) {
              this.$set(selectedItem, "shortCircuitResistance", "");
            }
          } else if (selectedItem.ampere <= 32) {
            this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
              if (Number(option.value) < 100) {
                if (this.selectedParent != null && !_.isEmpty(this.selectedParent.shortCircuitResistance)) {
                  if (Number(option.value) <= Number(this.selectedParent.shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                } else {
                  if (Number(option.value) <= Number(this.items[this.tabMainDividers].shortCircuitResistance)) {
                    shortCircuitResistanceOptions.push(option);
                  }
                }
              }
            });
            if (selectedItem.shortCircuitResistance >= 100) {
              selectedItem.shortCircuitResistance = "";
            }
          }
          this.shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
        }

        //* PHASE RULES *\\
        if (this.rule_SetPhase(selectedItem)) {
          this.$set(selectedItem, "phase", "3FN");
          // console.log("rule_SetPhase has changed the phase to = ", selectedItem.phase);
        }
        let products = "";
        //* SELECT PRODUCT *\\
        if (this.rule_ProductNotRequired(selectedItem)) {
          // PRODUCT NOT REQUIRED
          // console.log("Product not required");
          this.$set(selectedItem, "product", "");
          this.$set(selectedItem, "productNote", this.appConfig.texts.project.itemIsSecure);
          this.$set(selectedItem, "noProductReason", "");
          this.$set(selectedItem, "productOptions", []);
          // && !_.isEmpty(selectedItem.preAmp)
        } else if (!_.isEmpty(selectedItem.grid) && !_.isEmpty(selectedItem.ampere) && !_.isEmpty(selectedItem.shortCircuitResistance)) {
          // EXTERNAL LIGHTNING PROTECTION OR NOT
          if (this.project[0].external == 0 || this.project[0].external == 2) {
            // console.log("// NO EXTERNAL PROTECTION");
            // * FOR  SUBDIVIDER *\\
            if (selectedItem.type == 1) {
              // console.log("// SUBDIVIDER", selectedItem);
              products = this.sdMatrix.filter((item) => {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item["binnen"] == 1) {
                  return item;
                }
              });
            }
            // * FOR  CRITICAL END GROUPS *\\
            else if (selectedItem.type == 2) {
              products = this.ceMatrix.filter((item) => {
                if (_in == "binnen") {
                  if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item[ex] == 1 && item[_in] == 1) {
                    return item;
                  }
                } else {
                  if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpz] == 1) {
                    return item;
                  }
                }
              });
            }
          } else {
            // console.log("// EXTERNAL PROTECTION");
            // * FOR  SUBDIVIDER *\\
            if (selectedItem.type == 1) {
              // console.log("// SUBDIVIDER");
              // console.log("// buiten", _in, lpl, lpz);
              // console.log("// needs preAmp?", selectedItem.needsPreAmp);
              // console.log("// selected Item grid", selectedItem.grid);
              // console.log("// selected Item ampere", selectedItem.ampere);
              // console.log("// selected Item shortCircuitResistance", selectedItem.shortCircuitResistance);
              // console.log("// selected Item phase", selectedItem.phase);
              // console.log("// selected Item preAmp", `if-${selectedItem.needsPreAmp == "ja" ? selectedItem.preAmp : "nee"}`);
              products = this.sdMatrix.filter((item) => {
                if (_in == "binnen") {
                  if (this.checkItemSubDividerInsideWithExternal(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  }
                } else {
                  if (this.rule_IsItemOnRooftopConnected(selectedItem) && this.checkItemSubDividerOutsideWithExternalOnRoof(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  } else if (!this.rule_IsItemOnRooftopConnected(selectedItem) && this.checkItemSubDividerOutsideWithExternal(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  }
                }
              });
            }
            // * FOR  CRITICAL END GROUPS *\\
            else if (selectedItem.type == 2) {
              products = this.ceMatrix.filter((item) => {
                if (_in == "binnen") {
                  if (this.checkItemSubDividerInsideWithExternal(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  }
                } else {
                  if (this.rule_IsItemOnRooftopConnected(selectedItem) && this.checkItemSubDividerOutsideWithExternalOnRoof(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  } else if (!this.rule_IsItemOnRooftopConnected(selectedItem) && this.checkItemSubDividerOutsideWithExternal(selectedItem, item, { ex: ex, _in: _in, lpl: lpl, lpz: lpz })) {
                    return item;
                  }
                }
              });
            }
          }

          // if (products != undefined && products.length > 0 && !_.isEmpty(selectedItem.preAmp)) {
          if (products != undefined && products.length > 0) {
            if ((selectedItem.needsPreAmp == "ja" && !_.isEmpty(selectedItem.preAmp)) || selectedItem.needsPreAmp == "nee") {
              if (products[0] != undefined && products[0][selectedItem.grid] != null) {
                this.$set(selectedItem, "product", products[0][selectedItem.grid]);
                this.$set(selectedItem, "productNote", products[0].opmerkingen);
                this.$set(selectedItem, "noProductReason", "");
                if (products.length > 1) {
                  let productIdentifiers = [];
                  products.forEach((product) => {
                    productIdentifiers.push(product[selectedItem.grid]);
                  });
                  this.$set(selectedItem, "productOptions", productIdentifiers);
                }
              } else {
                this.$set(selectedItem, "productOptions", []);
                this.$set(selectedItem, "product", "");
                this.$set(selectedItem, "productNote", "");
                this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.foundButNoArticleNo} <b>${products[0].Name}</b> - <b>${selectedItem.grid}</b>`);
              }
            } else {
              this.$set(selectedItem, "productOptions", []);
              this.$set(selectedItem, "product", "");
              this.$set(selectedItem, "productNote", "");
              this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.foundButNoArticleNo} <b>${products[0].Name}</b> - <b>${selectedItem.grid}</b>`);
            }
          } else {
            this.$set(selectedItem, "productOptions", []);
            this.$set(selectedItem, "product", "");
            this.$set(selectedItem, "productNote", "");
            if (!_.isEmpty(selectedItem.preAmp)) {
              this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Fase:</b> ${selectedItem.phase}<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
            }
          }
          // console.log(products);
        } else {
          // console.log("Not complete item");
          this.$set(selectedItem, "product", "");
          this.$set(selectedItem, "productNote", "");
          this.$set(selectedItem, "noProductReason", "");
          this.$set(selectedItem, "productOptions", []);
        }
        this.autoUpdateProject();
      }, 600);
    },
  },
};
</script>
<style lang="scss">
.liSubItemsList {
  .ps {
    height: calc(100vh - 185px);
  }
}
.liEditItem {
  .inner {
    max-width: calc(100%);
    overflow: hidden;
  }
  .ps {
    height: calc(100vh - 190px);
    padding-bottom: 15px;
  }
}
</style>
