import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
const COOKIE_NAME = process.env.VUE_APP_COOKIE_NAME;
class LiApiService {
  getCollection(_data) {
    // const baseURI = ENV == 'development'? `${API_URL}requests/get.php?a-=getCollection`:`${API_URL}collection/getCollection`;
    const baseURI = `${API_URL}requests/get.php?a-=getCollection`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        // console.log(response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  createCollectionItem(object) {
    const baseURI = `${API_URL}requests/collection.php?a-=createCollectionItem`;
    object.guid = localStorage.getItem(COOKIE_NAME);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateCollectionItem(object) {
    const baseURI = `${API_URL}requests/collection.php?a-=updateCollectionItem`;
    object.guid = localStorage.getItem(COOKIE_NAME);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  removeCollectionItem(object) {
    const baseURI = `${API_URL}requests/collection.php?a-=removeCollectionItem`;
    object.guid = localStorage.getItem(COOKIE_NAME);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  moveCollectionItem(object) {
    const baseURI = `${API_URL}requests/collection.php?a-=moveCollectionItem`;
    object.guid = localStorage.getItem(COOKIE_NAME);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, object, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

  }

  // getQuestionsR() {
  //   const baseURI = API_URL + "/collection/getPresentation";
  //   const body = {
  //     object: "_presentation",
  //     where: "status [=>] 1 || identifier [=>] b6e200db-1c96-4a8d-b12a-3a4449c1bd0d",
  //     orderBy: "id ASC",
  //     select: "name, identifier",
  //     nodes: {
  //       object: "_presentation_question",
  //       select: "identifier, name, question, alertText, alertClass,  product,start,type, product_tns,product_tnc,product_tt",
  //       parentProperty: "presentation",
  //     },
  //     links: {
  //       object: "_presentation_link",
  //       select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
  //       parentProperty: "presentation",
  //     },
  //   };
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return axios
  //     .post(baseURI, body, config)
  //     .then(function (response) {
  //       return Promise.resolve(response.data.payload.questions);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  // getQuestionsI() {
  //   const baseURI = API_URL + "/collection/getPresentation";
  //   const body = {
  //     object: "_presentation",
  //     where: "status [=>] 1 || identifier [=>] 3d83fb34-2059-4a6a-97bd-2844f5061276",
  //     orderBy: "id ASC",
  //     select: "name, identifier",
  //     nodes: {
  //       object: "_presentation_question",
  //       select: "identifier, name, question, alertText, alertClass,  product, start,type, product_tns,product_tnc,product_tt",
  //       parentProperty: "presentation",
  //     },
  //     links: {
  //       object: "_presentation_link",
  //       select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
  //       parentProperty: "presentation",
  //     },
  //   };
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return axios
  //     .post(baseURI, body, config)
  //     .then(function (response) {
  //       return Promise.resolve(response.data.payload.questions);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  // getQuestionsU() {
  //   const baseURI = API_URL + "/collection/getPresentation";
  //   const body = {
  //     object: "_presentation",
  //     where: "status [=>] 1 || identifier [=>] 3d83fb34-2059-4a6a-97bd-2844f5061276",
  //     orderBy: "id ASC",
  //     select: "name, identifier",
  //     nodes: {
  //       object: "_presentation_question",
  //       select: "identifier, name, question, alertText, alertClass,  product, start,type, product_tns,product_tnc,product_tt",
  //       parentProperty: "presentation",
  //     },
  //     links: {
  //       object: "_presentation_link",
  //       select: "identifier, name, from, to, toPrefix, toSuffix, toValue,if,is",
  //       parentProperty: "presentation",
  //     },
  //   };
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return axios
  //     .post(baseURI, body, config)
  //     .then(function (response) {
  //       return Promise.resolve(response.data.payload.questions);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
}
export default new LiApiService();
